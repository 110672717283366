<template>
  <div>
    <CreateEditContatoDialog :item="item" v-model="editDialogOpened"
                                  @click:save="save"/>
    <v-row class="d-flex pa-3">
      <v-col cols="12" sm="8">
        <strong>Informe o(s) contato(s) referente(s) a este {{getTipoContato}}. </strong> 
      </v-col>
      <v-col cols="12" sm="4" class="d-flex justify-end">
        <v-btn small color="primary" @click="criar()">
            <v-icon>mdi-playlist-plus</v-icon>Adicionar
        </v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col cols="12" sm="12">
        <ContatoDataTable ref="dataTableContatos"
                        :elements="contatos"
                        @click:edit="editar"
                        @click:remove="remover"/>
      </v-col>
    </v-row>                        
  </div>
</template>

<script>
import ContatoDataTable from "../datatables/ContatoDataTable";
import CreateEditContatoDialog from "../dialogs/CreateEditContatoDialog";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {CreateEditContatoDialog, ContatoDataTable},
  props: {
    contatos: {
      type: Array,
      default: () => []
    },
    tipoContato:{
      type: String
    }
  },   
  data() {
    return {
      item: null,
      editDialogOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      idReverso: -1,
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
    getTipoContato(){
      return this.tipoContato === 'COM' ? "Comprador" : "Fornecedor";
    }
  },
  methods: {
    newIdReverso(){
      return (this.idReverso -= 1);
    },
    criar() {
      this.item = {
        id: 0,
        nome: "",
        cargo: "",
        cpf: "",
        telefone: "",
        celular: "",
        email: "",
        dataNascimento: "",
        senha: "",
      };
      this.editDialogOpened = true;
    },
    editar(itemLista) {
      // console.log('editar: ',itemLista);
      this.item = JSON.parse(JSON.stringify(itemLista));
      this.editDialogOpened = true;
    },
    save(newItem) {
      try {
        // console.log('quando retorna do form: ',newItem.id);
        if(parseInt(newItem.id) !== 0) {
          // console.log('Vai atualizar: ',newItem);
          const index = this.contatos.findIndex(item => item.id === newItem.id);
          this.$set(this.contatos, index, newItem);
          // console.log(index, this.contatos[index]);
        } else {
          // console.log('Vai adicionar: ',newItem);
          newItem.id = this.newIdReverso();
          this.contatos.push(newItem);
        }
        this.editDialogOpened = false;
      } catch (err) {
        this.$toast.error(`Não foi possível ${this.item.id ? 'atualizar' : 'adicionar'} o Responsável.`, {
          timeout: 6000
        })
      }
    },
    remover(delItem) {
      // console.log('Remover: ',delItem);
      try {
        const index = this.contatos.findIndex(item => item.id === delItem.id);
        this.contatos.splice(index, 1);
      } catch (err) {
        this.$toast.error(`Não foi possível remover o Responsável.`, {
          timeout: 6000
        })
      }
    },
    async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Contato.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>