<template>
<div>
  <v-row>
    <v-col cols="12" sm="6">
      <v-text-field label="* Nome"
                dense
                :rules="[
                  this.$validators.string.required,
                  v => this.$validators.string.greaterThanOrEquals(v, 1),
                  v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.nome"/>
    </v-col>
    <v-col cols="12" sm="3">
      <v-text-field label="* Cargo"
                dense
                :rules="[
                  this.$validators.string.required,
                  v => this.$validators.string.greaterThanOrEquals(v, 1),
                  v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.cargo"/>
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field label="* Celular"
                    dense
                    v-mask="'(##) #####-####'"
                    :rules="[
                      this.$validators.string.required,
                    ]"
                    v-model.trim="value.celular"/>
    </v-col>
    <!-- ----------------------------------------------------  -->
    <v-col cols="12" sm="4">
      <v-text-field label="CPF"
                    dense
                    placeholder="000.000.000-00"
                    :rules="[
                      this.$validators.string.cpf
                    ]"
                    v-mask="'###.###.###-##'"
                    v-model.trim="value.cpf"/>
    </v-col>    
    <v-col cols="12" md="4">
      <v-text-field label="Telefone"
                    dense
                    v-mask="'(##) ####-####'"
                    v-model.trim="value.telefone"/>
    </v-col> 
    <v-col cols="12" md="4">
      <v-text-field label="Data Nascimento"
                    dense
                    type="date"
                    v-model="value.dataNascimento" />
    </v-col>
    <!-- ----------------------------------------------------  -->
    <v-col cols="12" sm="6">
      <v-text-field label="* Email"
                    dense
                    :rules="[
                      this.$validators.string.required,
                      this.$validators.string.email,
                      v => this.$validators.string.lessThanOrEquals(v, 50),
                    ]"
                    v-model.trim="value.email"/>
    </v-col>
    <v-col cols="12" md="6">
      <span v-if="this.euEstouEditandoMeuCadastro" >Para alterar sua senha, faça pelo menu: Meus Dados -> Alterar Senha</span>
      <v-text-field v-else label="* Senha"
                    dense
                    type="password"
                    :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 100),
                    ]"
                    v-model="value.senha"/>
    </v-col>
  </v-row>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ContatoForm",
  props: {
    value: {
      type: Object
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated', 'nivelUsuarioLogado']),
    euEstouEditandoMeuCadastro(){
      return this.value.id === this.user.idResponsavel;
    }
  }
}
</script>

<style scoped>

</style>